body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* css reset */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, textarea {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: 'Pretendard-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}


/* custom styles */

a {
    text-decoration: none;

    /* &:visited {
         color: inherit;
     }
 */
}

/* font */
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

.margarine-regular {
    font-family: "Margarine", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.space-mono-regular {
    font-family: "Space Mono", monospace;
    font-weight: 400;
    font-style: normal;
}

.space-mono-bold {
    font-family: "Space Mono", monospace;
    font-weight: 700;
    font-style: normal;
}

.space-mono-regular-italic {
    font-family: "Space Mono", monospace;
    font-weight: 400;
    font-style: italic;
}

.space-mono-bold-italic {
    font-family: "Space Mono", monospace;
    font-weight: 700;
    font-style: italic;
}

/* font end */

button {
    cursor: pointer;
    background-color: transparent;
    border: none;

    font-family: 'Pretendard-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* Mobile - Landscape */
@media (min-width: 100px) and (max-width: 739px) {
    html {
        font-size: 50%;
    }
}

/* Tablet */
@media (min-width: 740px) and (max-width: 991px) {
    html {
        font-size: 50%;
    }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
    html {
        font-size: 62.5%;
    }
}

/* Desktop (Large) */
@media (min-width: 1200px) {
    html {
        font-size: 70%;
    }
}
